import '@/commons';
import Vue from 'vue';
import { onMounted, ref } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import vuetify from '@/config/plugins/vuetify';
import Header from '@/layout/header';
import Footer from '@/layout/footer';

const header = Header;
const footer = Footer;

const Index = Vue.extend({
  setup() {
    const dialog = ref(false);
    const toggleDig = () => {
      dialog.value = !dialog.value;
    };
    return {
      dialog,
      toggleDig,
    };
  },
});
export default new Index({
  el: '#index',
  vuetify,
  delimiters: ['[{', '}]'],
});
